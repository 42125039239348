<template>
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.13999 0.833496C4.41173 0.833496 0.607788 4.67299 0.607788 9.3657C0.607788 14.094 4.41173 17.8979 9.13999 17.8979C13.8327 17.8979 17.6722 14.094 17.6722 9.3657C17.6722 4.67299 13.8327 0.833496 9.13999 0.833496ZM13.2283 7.80146L8.67783 12.352C8.50007 12.5653 8.25122 12.6364 8.00236 12.6364C7.71795 12.6364 7.4691 12.5653 7.29134 12.352L5.01609 10.0767C4.62503 9.68565 4.62503 9.08129 5.01609 8.69023C5.40715 8.29917 6.01151 8.29917 6.40257 8.69023L8.00236 10.2545L11.8419 6.41498C12.2329 6.02392 12.8373 6.02392 13.2283 6.41498C13.6194 6.80604 13.6194 7.4104 13.2283 7.80146Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckFilled'
}
</script>
